import React, { useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { Action, Reducer } from '@reduxjs/toolkit';

import type { AppDispatch, RootState } from './store';
import { RequiredRootState, RootStateKeyType } from './storeTypes';

export const useInjectReducer = <Key extends RootStateKeyType>(params: {
  key: Key;
  reducer: Reducer<RequiredRootState[Key], Action>;
}) => {
  const store = useStore();
  const [isInjected, setIsInjected] = useState(false);

  React.useLayoutEffect(() => {
    store.injectReducer(params.key, params.reducer);
    setIsInjected(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isInjected;
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
