/**
 *
 * Asynchronously loads the component for App
 *
 */
import { lazyLoad } from 'utils/loadable';

export default lazyLoad(
  () => import('./App'),
  module => module.default,
);
