import { configureStore } from '@reduxjs/toolkit';

import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

// import logger from 'redux-logger';

import { createReducer } from './reducers';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const runSaga = sagaMiddleware.run;

  // Create the store with saga middleware
  // const middlewares: Middleware[] = [sagaMiddleware];
  // if (process.env.NODE_ENV !== 'production' && process.env.REDUX_LOGGER) {
  //   middlewares.push(logger);
  // }

  const enhancers = createInjectorsEnhancer({
    createReducer,
    runSaga,
  });

  return configureStore({
    reducer: createReducer(),
    preloadedState: {},
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: false,
      }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(enhancers),
  });
}
