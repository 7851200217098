import { createDraftSafeSelector } from '@reduxjs/toolkit';

// import { RootState } from 'types';

import { configureAppStore } from './configureStore';

export const store = configureAppStore();
export const { dispatch } = store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const createAppSelector = createDraftSafeSelector.withTypes<RootState>();
